export default (color) => {
  const styleElement = document.querySelector('#primaryColor')
  if (styleElement) {
    styleElement.remove()
  }
  const body = document.querySelector('body')
  const styleEl = document.createElement('style')
  const css = document.createTextNode(
    `:root { --kit-color-primary: ${color};}`,
  )
  styleEl.setAttribute('id', 'primaryColor')
  styleEl.appendChild(css)
  body.appendChild(styleEl)
}
