import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_STUDENT_CLASS({ commit, dispatch }, { schoolYearId }) {
      try {
        // console.log(schoolYearId)
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/kelas/student_class`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_tahun_ajaran: schoolYearId,
          },
        })

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_LIST_STUDENT_OF_CLASS(
      { commit, dispatch },
      { id, page, order, search, sortBy },
    ) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/kelas/student_class/list_student/${id}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              page,
              order,
              search,
              sortBy,
            },
          },
        )

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_ALL_STUDENT(
      { commit, dispatch },
      { id, page, order, search, sortBy, idNextLevel },
    ) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/kelas/list_student/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
            idNextLevel,
          },
        })

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async POST_STUDENT_CLASS_SELECTED(
      { commit, dispatch },
      { id, dataStudents, isNotify },
    ) {
      let isSuccess = false
      try {
        await axios(
          `${config.apiUrl}/api/kelas/student_class/add_student_selected/${id}`,
          {
            method: 'post',
            headers: {
              token: localStorage.token,
            },
            data: {
              dataStudents,
              isNotify,
            },
          },
        )
        isSuccess = true

        return new Promise((resolve) => {
          resolve(isSuccess)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async REMOVE_STUDENT_CLASS_SELECTED(
      { commit, dispatch },
      { dataStudents },
    ) {
      let isSuccess = false
      try {
        await axios(
          `${config.apiUrl}/api/kelas/student_class/remove_student_selected`,
          {
            method: 'post',
            headers: {
              token: localStorage.token,
            },
            data: {
              dataStudents,
            },
          },
        )
        isSuccess = true

        return new Promise((resolve) => {
          resolve(isSuccess)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(isSuccess)
        })
      }
    },
    async FETCH_GRADUATION_LETTER({ commit, dispatch }) {
      try {
        const {
          data: { data = {} },
        } = await axios(`${config.apiUrl}/api/murid/graduation-letter`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
}
