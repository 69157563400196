import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'
import router from './router'
import store from './store'
import './global.scss'
import './index.css'
import '../node_modules/jquery/dist/jquery.min.js'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueKonva from 'vue-konva'
// import 'leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// import 'leaflet.markercluster/dist/MarkerCluster.css'
// import 'leaflet.markercluster/dist/leaflet.markercluster-src.js'

// import { ZoomMtg } from '@zoomus/websdk'

// ZoomMtg.setZoomJSLib('https://source.zoom.us/1.7.8/lib', '/av')
// // ZoomMtg.setZoomJSLib('http://demo.hasanteknologi.id:8080/node_modules/@zoomus/websdk/dist/lib', '/av')
// ZoomMtg.preLoadWasm()
// ZoomMtg.prepareJssdk()

Vue.use(VueKonva)

Vue.use(CKEditor)
Vue.use(Antd)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
