import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import errorHandler from '@/helpers/errorHandler'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    allIsAttend: false,
    newestAssignmentResults: [],
    assignmentInProgresses: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    FETCH_ASSIGNMENT_IN_PROGRESS({ commit, dispatch }, { idMurid }) {
      axios(`${config.apiUrl}/api/dashboard-murid/aip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
        },
      })
        .then(({ data }) => {
          // console.log(data.data.rows)
          commit('SET_STATE', {
            assignmentInProgresses: data.data.rows,
          })
        })
        .catch(err => {
          errorHandler(err)
          console.log(err)
        })
    },
    FETCH_NEWEST_ASSIGNMENT_RESULT({ commit, dispatch }, { idMurid }) {
      axios(`${config.apiUrl}/api/dashboard-murid/newest`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
        },
      })
        .then(({ data: { data } }) => {
          commit('SET_STATE', {
            newestAssignmentResults: data,
          })
        })
        .catch(err => {
          errorHandler(err)
          console.log(err)
        })
    },
    async FETCH_ALL_ATTENDANCE({ commit, dispatch }, { idSession, idMapel, idKelas }) {
      try {
        let allAttendance = []
        const { data } = await axios(`${config.apiUrl}/api/attendance/by-session`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_sesi: idSession,
            id_mata_pelajaran: idMapel,
            id_kelas: idKelas,
          },
        })
        if (data.data) {
          allAttendance = data.data.attendances
          const { totalSesiMapel, attendanceCount } = data.data
          attendanceCount.forEach(murid => {
            allAttendance.forEach(muridAttendanceBySesi => {
              if (murid.id === muridAttendanceBySesi.id_murid) {
                muridAttendanceBySesi.percentageKehadiran = (murid.attendances.length / totalSesiMapel) * 100
              }
            })
          })
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(allAttendance)
          }, 1000)
        })
      } catch (err) {
        errorHandler(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_ATTENDANCE_BY_ID_MURID({ commit, dispatch }, { idMurid, idSession }) {
      let dataAttendanceMurid = {}
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      await axios(`${config.apiUrl}/api/attendance/assignment-murid`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
          id_sesi: idSession,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          dataAttendanceMurid = data
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
          errorHandler(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(dataAttendanceMurid)
        }, 1000)
      })
    },
    UPDATE_SINGLE_ABSENSI({ commit, dispatch }, { idKelasTahunAjaran, status, tanggalSesi, idSesi }) {
      // console.log(idAttendance, status)
      axios(`${config.apiUrl}/api/absensi/single-attend/`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          status_kehadiran: status,
          tanggal_sesi: tanggalSesi,
          id_kelas_tahun_ajaran: idKelasTahunAjaran,
          id_sesi: idSesi,
        },
      })
        .then(_ => {
        })
        .catch((err) => {
          errorHandler(err)
          console.log(err)
        })
    },
    async UPDATE_ALL_ABSENSI_BY_SESSION({ commit, dispatch }, { idKelas, tanggalSesi, idSesi }) {
      try {
        await axios(`${config.apiUrl}/api/absensi/all-attend`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            id_kelas: idKelas,
            tanggal_sesi: tanggalSesi,
            id_sesi: idSesi,
          },
        })

        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        errorHandler(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async SAVE_NOTES_BY_ID_ATTENDANCE({ commit, dispatch }, { idMurid, idSesi, notes }) {
      let isSuccess = false
      await axios(`${config.apiUrl}/api/attendance/update-notes/`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        params: { id_murid: idMurid, id_sesi: idSesi },
        data: {
          notes,
        },
      })
        .then(_ => {
          isSuccess = true
        })
        .catch((err) => {
          errorHandler(err)
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(isSuccess)
        }, 1000)
      })
    },
  },
}
