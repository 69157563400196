import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import router from '@/router'
import errorHandler from '@/helpers/errorHandler'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    isOnExam: false,
    mapelClasses: {},
    status: '',
    listCBTMurid: [],
    dataQuestionCBTStudent: {},
    totalCbtMurid: 0,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_MAPEL_BY_ID_TEACHER({ commit, dispatch, state }, { idGuru }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt/mapel-kelas`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
          },
        })

        const mapel = data.data
        return new Promise((resolve, reject) => {
          resolve(mapel)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_ALL_CBT_ATTENDANCES_BY_ID_CBT(
      { commit, dispatch, state },
      { idCbt },
    ) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt/all`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id: idCbt,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_TAHUN_AJARAN({ commit, dispatch, state }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/tahun_ajaran`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        const listTA = data.data
        const activeYear = data.activeYear
        return new Promise((resolve, reject) => {
          resolve({ listTA, activeYear })
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_CBT_TEACHER(
      { commit, dispatch, state },
      { idGuru, page, order, tipe, search, sortBy, idTahunAjaran },
    ) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            page,
            order,
            tipe,
            search,
            sortBy,
            id_tahun_ajaran: idTahunAjaran,
          },
        })
        const cbts = data.data
        const total = data.totalCBT
        return new Promise((resolve, reject) => {
          resolve({ cbts, total })
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_CBT_SCHEDULE_TEACHER(
      { commit, dispatch, state },
      { idGuru, page, order, tipe, search, sortBy },
    ) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt/supervision`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            page,
            order,
            tipe,
            search,
            sortBy,
          },
        })
        const cbts = data.data
        const total = data.totalCBT
        return new Promise((resolve, reject) => {
          resolve({ cbts, total })
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
      // console.log(tipe)
    },
    async FETCH_PENGAWAS({ commit, dispatch, state }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/guru`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })

        const teachers = data.data
        // console.log(teachers)
        return new Promise((resolve, reject) => {
          resolve(teachers)
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_CBT_BY_ID({ commit, dispatch, state }, { idCbt, idGuru }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt/${idCbt}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            isPpdb: localStorage.isPpdb,
          },
        })
        // console.log(data.cbt)
        const cbt = data.cbt
        const isAttendanceSubmitted = data.isAttendanceSubmitted
        const listPengawas = data.listPengawas
        // commit('SET_STATE', {
        //   mapelClasses: data.data,
        // })
        return new Promise((resolve, reject) => {
          resolve({
            cbt,
            isAttendanceSubmitted,
            listPengawas,
          })
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_CBT_SCHEDULE_BY_ID(
      { commit, dispatch, state },
      { idPengawasCbt },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/cbt/supervision/detail-head`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            params: {
              id_pengawas_cbt: idPengawasCbt,
            },
          },
        )
        const cbt = data.data
        // console.log(cbt)
        return new Promise((resolve, reject) => {
          resolve({
            cbt,
          })
        })
      } catch (error) {
        errorHandler(error)
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_CBT_SLOT({ commit, dispatch, state }, { idCbt, idGuru }) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/cbt/guru/mapel-check/${idCbt}`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            params: {
              id_guru: idGuru,
            },
          },
        )
        const message = data.message
        return new Promise((resolve, reject) => {
          resolve(message)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_SUPERVISION({ commit, dispatch, state }) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/cbt/supervision/get-schedule`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
          },
        )
        const res = data
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async REMOVE_SUPERVISION({ commit, dispatch, state }) {
      try {
        const res = await axios(
          `${config.apiUrl}/api/cbt/supervision/unlink-schedule`,
          {
            method: 'put',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
          },
        )
        const message = res.data?.message
        return new Promise((resolve, reject) => {
          resolve(message)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_CBT({ commit, dispatch, state }, { idCbt }) {
      try {
        await axios(`${config.apiUrl}/api/cbt/${idCbt}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => resolve())
      } catch (error) {
        errorHandler(error)
        console.log(error)
        return new Promise((resolve, reject) => reject(error))
      }
    },
    async FETCH_CBT_ATTENDANCES_BY_ID(
      { commit, dispatch, state },
      { idCbt, page, order, sortBy, idKelas },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/cbt/guru/grading-cbt/${idCbt}`,
          {
            method: 'get',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            params: {
              id_kelas: idKelas,
              page,
              order,
              sortBy,
              isPpdb: localStorage.isPpdb,
            },
          },
        )
        // .then(({ data }) => {
        // console.log(JSON.parse(data.data.cbt.cbt_attendances[0].jawaban))
        const cbt = data.data
        // })
        return new Promise((resolve, reject) => {
          resolve(cbt)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async POST_CBT(
      { commit, dispatch, state },
      { idGuru, dataCBT, cbtSoalBundle, pengawas },
    ) {
      try {
        let status = ''
        const { data } = await axios(`${config.apiUrl}/api/cbt/generate`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            id_guru: idGuru,
            ...dataCBT,
            cbtSoalBundle,
            pengawas,
          },
        })
        status = data.message
        return new Promise((resolve, reject) => {
          resolve(status)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
      // console.log('CBT DATA', dataCBT)
      // console.log('SOAL DATA', cbtSoalBundle)
    },
    async PUT_CBT(
      { commit, dispatch, state },
      { idCbt, idGuru, dataCBT, pengawas },
    ) {
      try {
        await axios(`${config.apiUrl}/api/cbt/edit-cbt`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            id_cbt: idCbt,
            id_guru: idGuru,
            ...dataCBT,
            pengawas,
          },
        })
        return new Promise((resolve, reject) => resolve())
      } catch (error) {
        errorHandler(error)
        console.log(error)
        return new Promise((resolve, reject) => reject(error))
      }
    },
    async PUT_CBT_SOAL(
      { commit, dispatch, state },
      { idCbt, idGuru, cbtSoalBundle, deletedQuestions },
    ) {
      try {
        let status = ''
        const { data } = await axios(`${config.apiUrl}/api/cbt/edit-cbtSoal`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            id_cbt: idCbt,
            id_guru: idGuru,
            cbtSoalBundle,
            deletedQuestions,
          },
        })
        status = data.message
        return new Promise((resolve, reject) => {
          resolve(status)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async APPROVE_CBT({ commit, dispatch, state }, { idCbt, idGuru }) {
      // console.log('CBT DATA', dataCBT)
      // console.log('SOAL DATA', cbtSoalBundle)
      try {
        await axios(`${config.apiUrl}/api/cbt/approval`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            id_cbt: idCbt,
            id_guru: idGuru,
          },
        })
        return new Promise((resolve, reject) => resolve())
      } catch (error) {
        errorHandler(error)
        console.log(error)
        return new Promise((resolve, reject) => reject(error))
      }
    },
    async PUT_ESSAY_ANSWER(
      { commit, dispatch, state },
      { id, idGuru, nilaiBundle },
    ) {
      try {
        // console.log('VUEX', id, nilaiBundle)
        const { data } = await axios(
          `${config.apiUrl}/api/cbt/guru/grading-essay/${id}`,
          {
            method: 'put',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            data: {
              id_guru: idGuru,
              nilaiBundle,
            },
          },
        )
        const status = data
        return new Promise((resolve, reject) => {
          resolve(status)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_CBT_MURID_LIST(
      { commit },
      { idMurid, searchCBT, order, page, sortBy, tipe, isPpdb },
    ) {
      try {
        const {
          data: { data, status, total },
        } = await axios(config.apiUrl + '/api/cbt/murid', {
          method: 'get',
          params: {
            id_murid: idMurid,
            search: searchCBT,
            order,
            page,
            sortBy,
            tipe,
            isPpdb,
          },
          headers: {
            token: localStorage.token,
          },
        })

        commit('SET_STATE', {
          listCBTMurid: data,
          status: status,
          totalCbtMurid: total,
        })

        return new Promise((resolve, reject) => {
          resolve(total)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_BRIEF_PAGE_STUDENT_CBT_EXAM({ commit }, { idMurid, idCBT }) {
      try {
        const {
          data: { data },
        } = await axios(config.apiUrl + '/api/cbt/murid/brief-page', {
          method: 'get',
          params: {
            id_murid: idMurid,
            id_cbt: idCBT,
            isPpdb: localStorage.isPpdb,
          },
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_STUDENT_CBT_QUESTION({ commit }, { idMurid, idCBT }) {
      try {
        const {
          data: { data },
        } = await axios(config.apiUrl + '/api/cbt/murid/exam-page', {
          method: 'get',
          params: {
            id_murid: idMurid,
            id_cbt: idCBT,
            isPpdb: localStorage.isPpdb,
          },
          headers: {
            token: localStorage.token,
          },
        })
        // console.log(data.soalRandom)
        data.exam.cbt_soals = data.soalRandom
        commit('SET_STATE', {
          dataQuestionCBTStudent: data.exam,
          isOnExam: true,
        })
        localStorage.setItem('isOnExam', true)
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
          localStorage.setItem('isOnExam', false)
          commit('SET_STATE', {
            isOnExam: false,
          })
        }
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async UPDATE_JAWABAN_MURID_CBT_EXAM(
      { commit },
      { jawaban, idCbtAttendance },
    ) {
      try {
        await axios(
          config.apiUrl + '/api/cbt/murid/do-exam/' + idCbtAttendance,
          {
            method: 'put',
            data: {
              jawaban,
            },
            headers: {
              token: localStorage.token,
            },
            params: {
              isPpdb: localStorage.isPpdb,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(true)
        })
        // console.log('suksesss')
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async SUBMIT_ANSWER({ commit }, { idCbtAttendance }) {
      try {
        await axios(
          config.apiUrl + '/api/cbt/murid/finish-exam/' + idCbtAttendance,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
          },
        )
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)

        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async UPDATE_START_TIME({ commit }, { idCbtAttendance }) {
      try {
        await axios(
          config.apiUrl + '/api/cbt/murid/start-time/' + idCbtAttendance,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            params: {
              isPpdb: localStorage.isPpdb,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(false)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      }
    },
    async CHECK_CBT_ATTENDACE({ commit }, { idMurid, idCbt }) {
      try {
        const {
          data: { data },
        } = await axios(config.apiUrl + '/api/cbt/murid/cbt-check-status', {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
            id_cbt: idCbt,
            isPpdb: localStorage.isPpdb,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        errorHandler(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_TABLE_SUPERVISION({ commit }, { idPengawasCbt }) {
      try {
        const { data } = await axios(
          config.apiUrl + '/api/cbt/supervision/detail-body',
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_pengawas_cbt: idPengawasCbt,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve({
            ...data.dataCBT,
            hitungCBTsoal: data.hitungCBTsoal,
          })
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_CBT_LOG({ commit }, { page, order }) {
      try {
        const { data } = await axios(config.apiUrl + '/api/cbt/log/nilai', {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { page, order },
        })

        return new Promise((resolve, reject) => {
          resolve({ log: data.data, total: data.total })
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_NOTES_BERITA_ACARA(
      { commit },
      { idPengawasCbt, notesBeritaAcara },
    ) {
      try {
        const { data } = await axios(
          config.apiUrl + '/api/cbt/supervision/update-notes-berita-acara',
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            data: {
              notes_berita_acara: notesBeritaAcara,
            },
            params: {
              id_pengawas_cbt: idPengawasCbt,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve({
            ...data.dataCBT,
            hitungCBTsoal: data.hitungCBTsoal,
          })
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_LIST_KELAS_GURU_PENGAMPU({ commit }) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/guru/pengampu/mata-pelajaran-dan-kelas`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_KD_BY_ID_MAPEL({ commit }, { idMapel }) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/kd/mata-pelajaran/${idMapel}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        errorHandler(err)
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async EDIT_GRADE_TOTAL_CBT({ commit }, { data, idCbt }) {
      let isSuccess = false
      try {
        await axios(config.apiUrl + '/api/cbt/grade', {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            data,
            id_cbt: idCbt,
          },
        })

        isSuccess = true
      } catch (err) {
        errorHandler(err)
      }

      return new Promise((resolve, reject) => {
        resolve(isSuccess)
      })
    },
    async UPDATE_EXAM_WORK_STATUS(
      { commit, dispatch, state },
      { dataAttendance, changeStartTime },
    ) {
      let isSuccess = false
      try {
        await axios(
          `${config.apiUrl}/api/cbt/supervision/update_status_attendance`,
          {
            method: 'put',
            headers: {
              'Access-Control-Allow-Origin': '*',
              token: localStorage.token,
            },
            data: {
              listMurid: dataAttendance,
              changeStartTime,
            },
          },
        )
        isSuccess = !isSuccess
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        errorHandler(error)
        console.log('error', error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async GET_DETAIL_CBT_AND_STUDENT_ANSWERS(
      { commit, dispatch, state },
      payload,
    ) {
      // console.log(payload)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/cbt/exam-sheet`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: payload,
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        errorHandler(error)
        console.log('error', error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
  },
}
