import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_ALL_PAKET_SOAL(
      { commit },
      { tipe, search, order, sortBy, limit, page },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/paket-soal`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            tipe,
            search,
            order,
            sortBy,
            limit,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_PAKET_SOAL_BY_ID(
      { commit },
      { id },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/paket-soal/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async DELETE_PAKET_SOAL({ commit, dispatch, state }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/paket-soal/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_PAKET_SOAL(_, payload) {
      try {
        await axios(`${config.apiUrl}/api/paket-soal/${payload.id_paket_soal}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
}
