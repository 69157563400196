import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    allIsAttend: false,
    newestAssignmentResults: [],
    assignmentInProgresses: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_DETAIL_SCHOOL_YEAR({ commit, dispatch }, { idSchoolYear }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/tahun_ajaran/admin/${idSchoolYear}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_MONTHS_LIST({ commit, dispatch }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/tahun_ajaran/month`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
}
