import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    menuSlots: [],
    overviewReport: {},
    detailReport: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_MENU_REPORT_TEACHER({ commit, dispatch, state }, payload = {}) {
      try {
        const { idTahunAjaran } = payload
        commit(
          'SET_STATE',
          {
            isLoading: true,
          },
          { root: true },
        )
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/report-mapel/tab`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_tahun_ajaran: idTahunAjaran,
          },
        })
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_OVERVIEW_ASSIGNMENT_TEACHER(
      { commit, dispatch, state },
      { idGuru, idMapel, tingkat },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/assesment/overview`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_mata_pelajaran: idMapel,
              id_guru: idGuru,
              tingkat,
            },
          },
        )

        const overviewReport = data.data

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(overviewReport)
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_REPORT_MAPEL_BY_LEVEL(
      { commit, dispatch, state },
      { idMapel, idLevel, idTahunAjaran },
    ) {
      try {
        const {
          data: { data, listKd, reportMapelIsExist },
        } = await axios(
          `${config.apiUrl}/api/report-mapel/by-level-and-mapel`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_mata_pelajaran: idMapel,
              id_level: idLevel,
              id_tahun_ajaran: idTahunAjaran,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve({ data, listKd, reportMapelIsExist })
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_DETAIL_RAPORT(
      { commit, dispatch, state },
      { idMapel, idKelas, reportType, gradeTypeReport },
    ) {
      // let dataTable
      commit(
        'SET_STATE',
        {
          isLoading: true,
        },
        { root: true },
      )
      const {
        data: { data, listKd, isKdMuridExist, programType },
      } = await axios(`${config.apiUrl}/api/report-mapel/by-class-and-mapel`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_mata_pelajaran: idMapel,
          id_kelas: idKelas,
          type_report: reportType,
          grade_type_report: gradeTypeReport,
        },
      })

      // dataTable = data.data.detail
      // console.log(dataTable)
      // commit('SET_STATE', {
      //   detailReport: data.data,
      // })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            data,
            listKd,
            isKdMuridExist,
            programType,
          })
        }, 1000)
      })
    },
    async PUT_PH({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', payload.data)
      let isSuccess = false
      let dataTable
      await axios(`${config.apiUrl}/api/assesment/edit-reports`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          data: payload.data,
        },
      })
        .then((res) => {
          isSuccess = true
          const idMapel = payload.idMapel
          const idKelas = payload.idKelas
          dispatch('FETCH_DETAIL_RAPORT', { idMapel, idKelas }).then((res) => {
            dataTable = res
            // console.log('data vuex', dataTable)
          })
        })
        .catch((err) => {
          isSuccess = false
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            isSuccess,
            dataTable,
          })
        }, 2000)
      })
    },
    async FETCH_GENERAL_DATA_RAPORT_BY_CLASS_MAPEL(
      { commit, dispatch, rootState },
      { idMapel, idKelas },
    ) {
      try {
        // console.log('masuk', payload.data)
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/report-mapel/general-by-class-and-mapel`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_mata_pelajaran: idMapel,
              id_kelas: idKelas,
            },
          },
        )
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data)
          }, 2000)
        })
      } catch (err) {
        console.log(err.response)
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
      }
    },
    async UPDATE_KD_BY_LEVEL_AND_SUBJECT(
      { commit, dispatch, rootState },
      { idMapel, tingkat, kdAmount },
    ) {
      try {
        await axios(
          `${config.apiUrl}/api/report-mapel/update-kd-by-level-and-subject`,
          {
            method: 'post',
            headers: {
              token: localStorage.token,
            },
            data: {
              id_mata_pelajaran: idMapel,
              tingkat,
              kdAmount,
            },
          },
        )

        return new Promise((resolve) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        if (err.response.data.message === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
      }
    },
    async UPDATE_REPORT_BY_CLASS_AND_MAPEL(
      { commit, dispatch, rootState },
      { idMapel, savedData, tipe, time, kumer, assesmentType },
    ) {
      try {
        await axios(
          `${config.apiUrl}/api/report-mapel/update-report-on-class-and-subject`,
          {
            method: 'put',
            headers: {
              token: localStorage.token,
            },
            data: {
              savedData,
              tipe,
              time,
              id_mata_pelajaran: idMapel,
              kumer,
              assesmentType,
            },
          },
        )

        return new Promise((resolve) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve) => {
          resolve(false)
        })
      }
    },
    async GET_SESI_BY_ID_MURID(
      { commit, dispatch, rootState },
      { idMurid, idMapel },
    ) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/sesi/by-murid-and-subject`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
            id_mata_pelajaran: idMapel,
          },
        })
        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_REPORT_SUBMISSION_ACTIVE(
      { commit, dispatch, rootState },
      { reportType, idKelas },
    ) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/report_setting`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            tipe: reportType,
            idKelas,
          },
        })
        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },

    async FETCH_ALL_TAHUN_AJARAN({ commit, dispatch, rootState }) {
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/tahun_ajaran`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          // params: {
          //   tipe: reportType,
          // },
        })
        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_REPORT_SUBMISSION(
      { commit, dispatch, rootState },
      { page, order, search, level, sortBy },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/report_setting/all`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              page,
              order,
              search,
              level,
              sortBy,
            },
          },
        )
        return new Promise((resolve) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_DETAIL_GENERAL_REPORT_BY_MURID(
      { commit, dispatch, state },
      { idMurid, tipe, tingkat, semester },
    ) {
      let isSuccess = false
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/general_report/murid/${idMurid}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: { tipe, tingkat, semester },
          },
        )

        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        isSuccess = true
        const generalReport = data.data
        const isPublished = data.isPublished

        return new Promise((resolve, reject) => {
          resolve({ isSuccess, generalReport, isPublished })
        })
      } catch (err) {
        const status = err.response.status
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        isSuccess = false
        if (status === 404) {
          router.push({ name: '404' })
        }
      }
      return new Promise((resolve, reject) => {
        resolve(isSuccess)
      })
    },
    async SYNC_CBT(
      { commit, dispatch, state },
      { typeReport, typeCbt, idMapel, idKelas },
    ) {
      try {
        await axios(`${config.apiUrl}/api/report-mapel/sync/`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          params: {
            report_type: typeReport,
            cbt_type: typeCbt,
            id_mata_pelajaran: idMapel,
            id_kelas: idKelas,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_PUBLIC_REPORT_PREVIEW(
      { commit, dispatch, state, rootState },
      { uuid },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/public/report_preview/${uuid}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        const generalReport = data.data

        return new Promise((resolve, reject) => {
          resolve(generalReport)
        })
      } catch (err) {
        const status = err.response.status
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        if (status === 404) {
          router.push({ name: '404' })
        }
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async CREATE_GHOST_ASSIGNMENT(
      { commit, dispatch, state, rootState },
      { idKd, idKelas },
    ) {
      try {
        await axios(`${config.apiUrl}/api/report-mapel/ghost-assignment`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_kd_master: idKd,
            id_kelas: idKelas,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_GHOST_ASSIGNMENT(
      { commit, dispatch, state, rootState },
      { idSesi },
    ) {
      try {
        await axios(`${config.apiUrl}/api/report-mapel/ghost-assignment`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_sesi: idSesi,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async CREATE_KD_MURID_BY_SUBJECT_AND_CLASS(
      { commit, dispatch, state, rootState },
      { idMapel, idKelas },
    ) {
      try {
        await axios(`${config.apiUrl}/api/kd-murid/bulk-by-subject-and-class`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_kelas: idKelas,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async CREATE_REPORT_MAPEL_BY_SUBJECT_AND_LEVEL(
      { commit, dispatch, state, rootState },
      { idMapel, idLevel },
    ) {
      try {
        await axios(`${config.apiUrl}/api/report-mapel/generate`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_level: idLevel,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_LIST_GRADE_BY_STUDENT(
      { commit, dispatch, state, rootState },
      { idKdMaster, idKelasTahunAjaran, type, idMapel },
    ) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/murid/get-list-grade-student-kd`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_kd_master: idKdMaster,
              id_kelas_tahun_ajaran: idKelasTahunAjaran,
              type,
              id_mata_pelajaran: idMapel,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_OVERVIEW_REPORT_MAPEL_BY_LEVEL(
      { commit, dispatch, state },
      { idMapel, idLevel, idTahunAjaran },
    ) {
      try {
        const {
          data: { data: overviewReport },
        } = await axios(
          `${config.apiUrl}/api/report-mapel/overview-by-level-and-mapel`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_mata_pelajaran: idMapel,
              id_level: idLevel,
              id_tahun_ajaran: idTahunAjaran,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(overviewReport)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_METADATA_DETAIL_GENERAL_REPORT_BY_MURID(
      { commit, dispatch, state },
      { idMurid, tipe, idLevel, semester },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/general_report/metadata/murid/${idMurid}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: { tipe, id_level: idLevel, semester },
          },
        )

        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        const generalReport = data.metadata
        const isPublished = data.isPublished
        const kumer = data.kumer

        return new Promise((resolve, reject) => {
          resolve({ generalReport, isPublished, kumer })
        })
      } catch (err) {
        // const status = err.response.status
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        // if (status === 404) {
        //   router.push({ name: '404' })
        // }
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_METADATA_PUBLIC_REPORT_PREVIEW(
      { commit, dispatch, state, rootState },
      { uuid },
    ) {
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/public/metadata/report_preview/${uuid}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        const generalReport = data.metadata

        return new Promise((resolve, reject) => {
          resolve(generalReport)
        })
      } catch (err) {
        const status = err.response.status
        commit(
          'SET_STATE',
          {
            isLoading: false,
          },
          { root: true },
        )
        if (status === 404) {
          router.push({ name: '404' })
        }
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_REPORT_HISTORY({ commit, dispatch, state }, { type, idMurid }) {
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/general_report/homeroom/student-report`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_murid: idMurid,
              type,
            },
          },
        )

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
  },
}
