import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    signature: '',
    isLive: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    ZOOM_MEETINGS({ commit }) {
      axios(`${config.apiUrl}/api/create-room`, {
        method: 'post',
        headers: {
          token: localStorage.token,
        },
        data: {
          meetingNumber: 1,
          role: 0,
        },
      })
        .then(data => {
        })
    },
    async FETCH_ALL_MURID_FOR_ABSENSI({ commit, dispatch }, { idSession, idMapel, idKelas, idModel }) {
      try {
        // let allAttendance = []
        const { data: { data: { attendances, attendanceAuthorized, isAttendanceSubmitted } } } = await axios(`${config.apiUrl}/api/kelas_tahun_ajaran/absensi`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_sesi: idSession,
            id_mata_pelajaran: idMapel,
            id_kelas: idKelas,
            id_model: idModel,
          },
        })
        // if (data.data) {
        //   allAttendance = data.data.attendances
        //   const { totalSesiMapel, attendanceCount } = data.data
        //   attendanceCount.forEach(murid => {
        //     allAttendance.forEach(muridAttendanceBySesi => {
        //       if (murid.id === muridAttendanceBySesi.id_murid) {
        //         muridAttendanceBySesi.percentageKehadiran = (murid.attendances.length / totalSesiMapel) * 100
        //       }
        //     })
        //   })
        // }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ attendances, attendanceAuthorized, isAttendanceSubmitted })
          }, 1000)
        })
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
}
