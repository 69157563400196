import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_COUNSELING_TYPE({ page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/konseling/kategori`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { page },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_COUNSELING_TYPE(_, { newData = {} }) {
      try {
        const { data: { data = {} } } = await axios(`${config.apiUrl}/api/konseling/kategori`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            name: newData.nama,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async PUT_COUNSELING_TYPE(_, { newData, id }) {
      try {
        const { data: { data = {} } } = await axios(`${config.apiUrl}/api/konseling/kategori/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            name: newData.nama,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async DELETE_COUNSELING_TYPE(_, { id }) {
      try {
        await axios(`${config.apiUrl}/api/konseling/kategori/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_COUNSELING_LIST(_, { search, id_kategori_bk: idKategori, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/konseling/list`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { search, id_kategori_bk: idKategori, page },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_COUNSELING_LIST_FIELDS() {
      try {
        const { data: { data = {} } } = await axios(`${config.apiUrl}/api/konseling/fields`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_COUNSELING_LIST(_, { newData }) {
      try {
        const { data: { data = {} } } = await axios(`${config.apiUrl}/api/konseling/list`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async DELETE_COUNSELING_LIST(_, { id }) {
      try {
        await axios(`${config.apiUrl}/api/konseling/list/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        console.log(error)
      }
    },
    async PUT_COUNSELING_LIST(_, { newData, id }) {
      try {
        const { data: { data = {} } } = await axios(`${config.apiUrl}/api/konseling/list/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
